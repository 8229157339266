<template>
  <div id="contacts-view">
    <contacts-table
      v-if="showContactTable === true"
      v-bind:showContactTable="showContactTable"
      v-on:addContact="addContact"
      v-on:contactEdit="contactEdit"
    ></contacts-table>
    <create-edit
      v-if="showContactTable === false"
      v-on:closeContactEdit="closeContactEdit"
      v-bind:contactData="contactData"
      v-bind:edit="edit"
    ></create-edit>
  </div>
</template>

<script>
import ContactsTable from "@/views/site/contact/ContactsTable";
import CreateEdit from "@/views/site/contact/CreateEdit";

export default {
  name: "ContactsView",

  components: {
    ContactsTable,
    CreateEdit,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Manage your contacts effortlessly with our intuitive interface. Add, edit, and organize your contacts seamlessly. Whether you’re creating new entries or updating existing ones, streamline your contact management process today!",
      },
      {
        name: "keywords",
        content:
          "contacts management, add contacts, edit contacts, contact list, user-friendly interface, contact organization, contact table, contact details, manage contacts, contact creation, contact editing, address book, contact features, efficient contact management, personal contacts",
      },
    ],
  },
  data() {
    return {
      edit: false,
      contactData: [],
      showContactTable: true,
      filter: null,
    };
  },
  methods: {
    addContact() {
      this.showContactTable = false;
      this.edit = false;
      this.contactData = [];
    },
    contactEdit(contact) {
      this.edit = true;
      this.showContactTable = false;
      this.contactData = contact.item;
    },

    closeContactEdit() {
      this.showContactTable = true;
    },
  },
};
</script>
