<template>
  <div class="container-fluid my-5">
    <!-- <b-overlay :show="loader" rounded="sm"> -->
    <div class="card card-default">
      <!-- /.card-header -->
      <div class="card-header">
        <h3 class="card-title" v-if="edit">Contact Edit</h3>
        <h3 class="card-title" v-else>Add Contact</h3>
      </div>
      <!-- /.card-body -->
      <div class="card-body content">
        <div class="form-row">
          <div class="col-md-5 mb-2">
            <label class="font-weight-600"
              >Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.name }"
              v-model="contactData.contact_name"
              placeholder="Name"
            />
            <!-- <span class="text-danger" v-if="errors.name">{{
              this.errors.name[0]
            }}</span> -->
            <span class="text-danger" v-if="errors.name">{{
              this.errors.name[0]
            }}</span>
          </div>

          <div class="col-md-7 mb-2">
            <label class="font-weight-600">Email</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.email }"
              v-model="contactData.email"
              placeholder="Email"
            />
            <!-- <span class="text-danger" v-if="errors.email">{{
              this.errors.email[0]
            }}</span> -->
          </div>
          <div class="col-md-5 mb-2">
            <label class="font-weight-600"
              >Phone Number<span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.mobile1 }"
              v-model.number="contactData.mobile1"
              @keypress="isNumber($event)"
              placeholder="Mobile"
              maxlength="15"
            />
            <!-- <span class="text-danger" v-if="errors.mobile1">{{
              this.errors.mobile1[0]
            }}</span> -->
            <span class="text-danger" v-if="errors.mobile1">{{
              this.errors.mobile1[0]
            }}</span>
          </div>
          <!-- <div class="col-md-5 mb-2">
            <label class="font-weight-600"
              >Mobile 2</label
            >
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.mobile2 }"
              v-model="contactData.mobile2"
              placeholder="Mobile 2"
            />
          </div> -->
          <div class="col-md-2">
            <div class="form-group">
              <b-form-group class="font-weight-600">
                <label class="mb-2 d-block font-weight-600">Gender</label>
                <div class="row">
                  <div class="col-md-6">
                    <b-form-radio v-model="gender" name="gender" value="Male"
                      >Male</b-form-radio
                    >
                  </div>
                  <div class="col-md-6">
                    <b-form-radio v-model="gender" name="gender" value="Female"
                      >Female</b-form-radio
                    >
                  </div>
                  <!-- <span class="text-danger" v-if="errors.gender">{{
                    this.errors.gender[0]
                  }}</span> -->
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-5 my-2">
            <label class="mb-4 d-block font-weight-600"
              >Persona<span class="text-danger"></span
            ></label>
            <span v-for="(persona, index) in personas">
              <img
                :src="persona.icon_persona"
                class="avatar-img mx-2 mt-2"
                style="border: 1px solid #ddd"
                alt=""
              />
              <a class="button1 btn btn-danger" @click="removepersona(index)"
                ><i style="font-size: 11px !important" class="fa fa-times"></i
              ></a>
            </span>
            <br />
            <b-button
              size="sm"
              variant="outline-primary mx-1 mt-3"
              class=" pl-3 col-md-4"
              @click="showModal"
              :disabled="personas.length >= 3"
              v-b-popover.hover.top="'Select Persona'"
            >
              <i class="fa fa-edit mr-2"></i>
              Select</b-button
            >
          </div>
          <div class="col-md-5 my-2">
            <label class="mb-4 d-block font-weight-600"
              >Activelifestyle<span class="text-danger"></span
            ></label>
            <span v-for="(activelifestyle, index) in activelifestyles">
              <img
                :src="activelifestyle.icon_style"
                class="avatar-img mx-2 mt-2"
                style="border: 1px solid #ddd"
                alt=""
              />
              <a
                class="button1 btn btn-danger"
                @click="removeactivelifestyle(index)"
                ><i class="fa fa-times"></i
              ></a>
            </span>
            <br />
            <b-button
              size="sm"
              variant="outline-primary mx-1 mt-3"
              class="pl-3 col-md-4"
              @click="showactiveModal"
              :disabled="activelifestyles.length >= 3"
              v-b-popover.hover.top="'Select Activelifestyle'"
            >
              <i class="fa fa-edit mr-2"></i>Select</b-button
            >
          </div>
          <div class="col-md-5 my-2">
            <label class="mb-4 d-block font-weight-600"
              >Character Trait<span class="text-danger"></span
            ></label>
            <img
              v-if="charactertrait"
              :src="charactertrait.icon_name"
              class="avatar-img mx-2 mt-2"
              style="border: 1px solid #ddd"
              alt=""
            />

            <br />
            <b-button
              size="sm"
              variant="outline-primary mx-1 mt-3"
              class="pl-3 col-md-4"
              @click="showcharacterModal"
              v-b-popover.hover.top="'Select CharacterTrait'"
            >
              <i class="fa fa-edit mr-2"></i>Select</b-button
            >
          </div>

          <!-- <div class="col-md-5 mb-2">
              <label class="font-weight-600">Character Trait<span class="text-danger">*</span></label>
               <img v-for="charactertrait in charactertraits" :src="charactertrait.icon_name" class="avatar-img" alt=""><br>
    
            </div> -->
        </div>
      </div>
      <!-- /.card-footer -->
      <div class="card-footer">
        <div class="col-md-12 center py-3">
          <center>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm col-sm-1 mb-2 mr-3"
              @click="saveContact()"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm col-sm-1 mb-2 mr-1"
              @click="cancelForm()"
            >
              Cancel
            </button>
          </center>
        </div>
      </div>
    </div>
    <b-modal
      id="selectactive-modal"
      centered
      title="Active Lifestyle"
      size="mb"
      @shown="setActiveLifeStyleImgLimit()"
    >
      <section
        class="mx-3"
        style="max-height: 480px; overflow-y: scroll; overflow-x: hidden"
      >
        <p class="text-center text-primary font-weight-bold mb-3">
          Select 1 to 3 Active Lifstyles
        </p>
        <div class="form-row">
          <div class="col-md-12 my-3">
            <vue-select-image
              :dataImages="styleImages"
              :is-multiple="true"
              :h="'65px'"
              :w="'100px'"
              :limit="active_lifestyle_img_limit"
              @onselectmultipleimage="onSelectMultipleImage"
            >
            </vue-select-image>
          </div>
        </div>
      </section>
      <template #modal-footer="{ ok }">
        <button class="btn btn-primary my-4 col-md-12" @click="selectStyle">
          OK
        </button>
      </template>
    </b-modal>
    <b-modal
      id="selectpersona-modal"
      centered
      title="Personas"
      size="mb"
      @shown="setbalanceImgCount()"
    >
      <section
        class="mx-3"
        style="max-height: 480px; overflow-y: scroll; overflow-x: hidden"
      >
        <p class="text-center text-primary font-weight-bold mb-3">
          Select 1 to 3 Personas
        </p>
        <div class="form-row">
          <div class="col-md-12 my-3">
            <vue-select-image
              :dataImages="dataImages"
              :is-multiple="true"
              :h="'65px'"
              :w="'100px'"
              :limit="balance_image_count"
              @onselectmultipleimage="onSelectMultiplePersona"
            >
            </vue-select-image>
          </div>
        </div>
      </section>
      <template #modal-footer="{ ok }">
        <button class="btn btn-primary my-4 col-md-12" @click="selectPersona">
          ok
        </button>
        <!-- <label  v-if="personas.length!=0 && balance_img_count>=all_persona.length" class="text-danger text-center">You have save only 3 perosonas iamges</label> -->
      </template>
    </b-modal>
    <b-modal id="selectcharacter-modal" centered title="" size="mb">
      <section
        class="mx-3"
        style="max-height: 480px; overflow-y: scroll; overflow-x: hidden"
      >
        <img
          src="@/assets/site/images/loader.gif"
          v-if="loader === true"
          class="img-fluid loader-width"
          alt
        />
        <div class="form-row">
          <div class="col-md-12 my-3">
            <vue-select-image
              :loader="false"
              :dataImages="characterImages"
              :h="'65px'"
              :w="'100px'"
              @onselectimage="onSelectCharacter"
            >
            </vue-select-image>
          </div>
        </div>
      </section>
      <template #modal-footer="{ ok }">
        <button class="btn btn-primary my-4 col-md-12" @click="selectCharacter">
          ok
        </button>
      </template>
    </b-modal>
    <!-- </b-overlay> -->
  </div>
</template>

<script>
import VueSelectImage from "vue-select-image";
require("vue-select-image/dist/vue-select-image.css");
export default {
  name: "CreateEdit",
  props: ["contactData", "edit"],
  components: {
    VueSelectImage,
  },
  data() {
    return {
      loader: false,
      errors: [],
      gender: "",
      all_persona: [],
      wizard_Details: [],
      personas: [],
      charactertraits: [],
      activelifestyles: [],
      gender: null,
      type: null,
      allpersonas: [],
      characterImages: null,
      dataImages: null,
      styleImages: null,
      styles: [],
      active_lifestyle: [],
      persona_ids: [],
      style_ids: [],
      charactertrait: "",
      caricature_trait: null,
      balance_image_count: 3,
      active_lifestyle_img_limit: 3,
      age: null,
      type: null,
    };
  },
  created() {
    this.setSelectOptions();
    this.getWizardDetails();
    // this.getPersonas();
    // this.getActivelifestyles();

    // this.getCharactertraits();
  },
  methods: {
    isNumber(evt) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    setbalanceImgCount() {
      this.balance_image_count = 3 - this.personas.length;
    },
    setActiveLifeStyleImgLimit() {
      this.active_lifestyle_img_limit = 3 - this.activelifestyles.length;
    },
    setSelectOptions() {
      this.gender = "";
      if (this.edit === true) {
        this.gender = this.contactData.gender;
      }
    },
    saveContact() {
      //Save or update User Details
      this.errors = [];
      this.loader = true;
      this.getIds();
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      // var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      if (this.edit === false) {
        method_type = "post";
        fetch_url = process.env.VUE_APP_URL + "customer/usercontact";
      } else {
        method_type = "put";
        fetch_url =
          process.env.VUE_APP_URL +
          "customer/usercontact/" +
          this.contactData.id;
      }
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          name: this.contactData.contact_name,
          mobile1: this.contactData.mobile1,
          mobile2: this.contactData.mobile2,
          email: this.contactData.email,
          gender: this.gender,
          persona: this.persona_ids,
          activelifestyle: this.style_ids,
          user_id: user_id,
          charactertrait: this.charactertrait.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            // this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
            this.$emit("closeContactEdit", true);
          } else if (data.status === false) {
            // this.loader = false;
            this.$swal.fire("Error", "Customer Data Not Saved !", "error");
          } else {
            // this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Customer Data", "Error : " + err.message, "error");
        });
    },

    cancelForm() {
      this.$emit("closeContactEdit", true);
    },
    showModal() {
      this.$bvModal.show("selectpersona-modal");
    },
    showactiveModal() {
      this.$bvModal.show("selectactive-modal");
    },

    showcharacterModal() {
      if (this.gender != null) {
        this.getCharactertraits(this.gender);
        this.$bvModal.show("selectcharacter-modal");
      } else {
        alert("Please Select gender ");
      }
    },

    getWizardDetails() {
      // this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      // var api_token = userdata_array.token;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/wizard/getWizardDetails";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: userdata_array.user.id,
          contact_id: this.contactData.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.wizardDetails != null) {
            this.wizard_Details = data.wizardDetails;
            this.personas = data.wizardDetails.persona;
            this.activelifestyles = data.wizardDetails.activeLifeStyle;
            this.charactertrait = data.wizardDetails.characterTrait;
            this.age = data.wizardDetails.ageRange.id;
            this.type = data.wizardDetails.ageGroup;
          }
          this.getActivelifestyles();
          this.getPersonas();
        });
    },
    removepersona(index) {
      //  console.log(this.personas);
      // this.personas.splice(this.personas.indexOf(persona), 1);
      this.personas.splice(index, 1);
      // console.log(personas);
    },
    removeactivelifestyle(index) {
      //  console.log(this.personas);
      // this.personas.splice(this.personas.indexOf(persona), 1);
      this.activelifestyles.splice(index, 1);
      // console.log(activelifestyles);
    },

    getPersonas() {
      this.loader = true;
      if (this.type) {
        var type = this.type.label;
      } else {
        var type = null;
      }
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      // var api_token = userdata_array.token;
      var fetch_url = process.env.VUE_APP_URL + "customer/persona/getPersonas";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          gender: this.gender,
          type: type,
          ch_type: "persona",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.allpersonas = data.caricatures;
          this.getImageArray(this.allpersonas);
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    onSelectMultiplePersona(images) {
      // console.log(images);
      this.balance_img_count = 3 - this.personas.length;

      this.all_persona = images;
    },
    onSelectCharacter(image) {
      this.caricature_trait = image;
      // console.log( this.caricature_trait);
    },
    getImageArray(allpersonas) {
      var img_array = new Array();

      allpersonas.forEach(function(allpersona) {
        img_array.push({
          id: allpersona.id,
          src: allpersona.icon_persona,
          alt: "",
          data: allpersona,
        });
      });

      this.dataImages = img_array;
    },

    getActivelifestyles() {
      this.loader = true;
      if (this.type) {
        var type = this.type.label;
      } else {
        var type = null;
      }
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      // var api_token = userdata_array.token;
      var fetch_url = process.env.VUE_APP_URL + "customer/persona/getPersonas";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          gender: this.gender,
          type: type,
          ch_type: "active_lifestyle",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.styles = data.caricatures;
          this.getStyleArray(this.styles);
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getCharactertraits(gender) {
      if (this.characterImages == null) {
        this.loader = true;
        if (this.type) {
          var type = this.type.label;
        } else {
          var type = null;
        }
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        // var api_token = userdata_array.token;
        var fetch_url =
          process.env.VUE_APP_URL + "customer/persona/getCharecterTraits";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            // Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            gender: gender,
            type: type,
            ch_type: "character_trait",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.charactertraits = data.charactertraits;
            if (this.charactertraits) {
              this.getCharacterArray(this.charactertraits);
            }
            // this.$emit('showSignupSuccess', true);
          })
          .catch(function(err) {
            console.log("Fetch Error :-S", err);
          });
      }
    },
    getCharacterArray(charactertraits) {
      var character_array = new Array();

      if (charactertraits != null) {
        // charactertraits.forEach(function (charactertrait) {
        // character_array.push({
        //   id: charactertrait.id,
        //   src: charactertrait.icon_name,
        //   alt: "",
        //   data: charactertrait,
        // });
        // });
        for (const charactertrait of Object.entries(charactertraits)) {
          character_array.push({
            id: charactertrait[1].id,
            src: charactertrait[1].icon_name,
            alt: "",
            data: charactertrait[1],
          });
        }
      }

      this.characterImages = character_array;
    },

    getStyleArray(styles) {
      var style_array = new Array();

      styles.forEach(function(style) {
        style_array.push({
          id: style.id,
          src: style.icon_style,
          alt: "",
          data: style,
        });
      });

      this.styleImages = style_array;
    },
    onSelectMultipleImage(images) {
      // console.log(images);
      this.active_lifestyle = images;
    },
    selectPersona() {
      if (this.personas != null) {
        var personaDummyArray = this.personas;
      } else {
        var personaDummyArray = new Array();
      }
      var persona_array = this.all_persona;
      persona_array.forEach(function(allpersona) {
        //console.log(allpersona);
        personaDummyArray.push(allpersona.data);
      });
      this.personas = personaDummyArray;
      console.log(this.personas.length);
      this.closeModal();
    },

    selectCharacter() {
      //console.log(this.charactertraits);
      this.charactertrait = this.caricature_trait.data;
      console.log(this.charactertrait);
      this.closeCharacterModal();
    },
    closeCharacterModal() {
      this.$bvModal.hide("selectcharacter-modal");
    },
    closeModal() {
      this.$bvModal.hide("selectpersona-modal");
    },

    selectStyle() {
      var array = this.activelifestyles;
      var persona_array = this.active_lifestyle;
      // console.log(array);
      if (persona_array != null) {
        persona_array.forEach(function(allpersona) {
          array.push(allpersona.data);
        });
      }
      this.activelifestyles = array;
      this.closeActiveModal();
    },
    closeActiveModal() {
      this.$bvModal.hide("selectactive-modal");
    },
    getIds() {
      var per_array = new Array();
      var sty_array = new Array();
      // this.personas;
      // this.activelifestyles;

      if (this.personas != null) {
        this.personas.forEach(function(persona) {
          per_array.push(persona.id);
        });
      }
      this.persona_ids = per_array;
      if (this.activelifestyles != null) {
        this.activelifestyles.forEach(function(persona) {
          sty_array.push(persona.id);
        });
      }
      this.style_ids = sty_array;
    },
  },
};
</script>
